import React from 'react';
import '../styles/footer.css';
import Container from 'react-bootstrap/Container';

const footer = {
  en: {
    tel: 'Tel',
    fax: 'Fax',
    email: 'E-mail',
    address: 'No 54, Nexi Rd., Luzhu Dist., Taoyuan City 33852, Taiwan R.O.C. Taiwan R.O.C',
  },
  zh: {
    tel: '電話',
    fax: '傳真',
    email: '信箱',
    address: '338桃園市蘆竹區內溪路54號',
  },
};

const Footer = ({ location }) => {
  return (
    <footer className="Footer">
      <Container>
        <p data-testid="footer-company" className="Footer--company">
          JNA Aircon
        </p>
        <p data-testid="footer-tel">
          {footer[location].tel} +886 3 3249006
          <span style={{ marginLeft: '20px' }}>{footer[location].fax}: +886 3 3249010</span>
        </p>
        <p data-testid="footer-email">{footer[location].email}: jnaaircon@hotmail.com</p>
        <address data-testid="footer-address">{footer[location].address}</address>
        <p data-testid="footer-copyright">Copyright © 2020 JNA Aircon. All rights reserved.</p>
      </Container>
    </footer>
  );
};

export default Footer;
