import React from 'react';
import '../styles/navbar-display.css';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import { Link } from 'gatsby';

const navs = {
  en: [
    // { title: 'Prodcuts', path: '/en/products' },
    // { title: 'Tech-Info', path: '/en/tech-info' },
    // { title: 'Catalog', path: '/en/catalog' },
    // { title: 'News', path: '/en/news' },
    // { title: 'About', path: '/en/about' },
    // { title: 'Contact', path: '/en/contact' },
  ],
  zh: [
    // { title: '產品', path: '/products' },
    // { title: '技術專區', path: '/tech-info' },
    // { title: '目錄', path: '/catalog' },
    // { title: '最新消息', path: '/news' },
    // { title: '關於', path: '/about' },
    // { title: '聯絡我們', path: '/contact' },
  ],
};

const NavbarDisplay = ({ location }) => {
  return (
    <Navbar collapseOnSelect expand="lg" bg="light" variant="light" className="Navbar">
      <Container>
        <Navbar.Brand>
          {location === 'zh' && (
            <Link to="/" className="Navbar--brand">
              <span style={{ fontWeight: 'bold', color: '#325269' }}>JNA</span> Aircon
            </Link>
          )}
          {location === 'en' && (
            <Link to="/en" className="Navbar--brand">
              <span style={{ fontWeight: 'bold', color: '#325269' }}>JNA</span> Aircon
            </Link>
          )}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            {navs[location].map(({ title, path }) => (
              <Navbar.Text key={title}>
                <Link to={path} className="Navbar--link">
                  {title}
                </Link>
              </Navbar.Text>
            ))}
            <NavDropdown title={location === 'en' ? 'Language' : '語言'} className="Navbar--dropdown">
              <NavDropdown.Item>
                <Link to="/en" style={{ color: '#333' }}>
                  English
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <Link to="/" style={{ color: '#333' }}>
                  中文
                </Link>
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarDisplay;
