import React, { useEffect, useState } from 'react';
import '../styles/layout.css';

// Components
import NavbarDisplay from './navbar-display';
import Footer from './footer';

const Layout = (props) => {
  const { children } = props;
  const [location, setLocation] = useState('zh');

  useEffect(() => {
    if (window.location.pathname.includes('en')) {
      setLocation('en');
    }
  });

  return (
    <div className="Layout">
      <NavbarDisplay location={location} />
      <main style={{ marginTop: '50px' }}>{children}</main>
      <Footer location={location} />
    </div>
  );
};

export default Layout;
